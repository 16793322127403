<template>
  <mdb-container>
    <mdb-col>
      <div class="card mx-xl-5 mt-5 sub_content">
        <h3>시제품 의뢰</h3>
        <p>여러분들의 아이디어로 문의 해주시면 아쎄따 및 협력사가 <strong style="color: #2b4999">시제품 아이디어에 대한
          제작 여부를 논의하여 작성자와 함께 진행</strong>하는 작성칸 입니다.</p>
      </div>
      <div class="card mx-xl-5 mt-3 p-3 mb-5">
        <div class="card-body">
          <form>
            <h5 class="sub-title">작성자</h5>
            <input type="text" id="writer" class="form-control" v-model="writer">
            <br>
            <h5 class="sub-title">이메일</h5>
            <input type="email" id="email" class="form-control" v-model="email">
            <br>
            <h5 class="sub-title">분야</h5>
            <mdb-select
                class="sel_box size-custom "
                color="primary"
                @getValue="getSelectValue"
                v-model="fields"
                placeholder="항목을 선택하세요."
            />
            <br>
            <h5 class="sub-title">제목</h5>
            <input type="text" id="title" class="form-control" v-model="title">
            <br>
            <h5 class="sub-title">상세내용</h5>
            <textarea type="text" id="contents" class="form-control" rows="3" v-model="contents"></textarea>
            <br>
            <h5 class="sub-title">첨부파일</h5>
            <mdb-file-input
                class="file-input-doc size-custom"
                btnTitle="문서파일 선택"
                @getValue="getFileInputValue"
                icon-right="upload"
                textFieldTitle="excel / pdf / hwp "
                sm
                accept="application/vnd.ms-excel, .pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .hwp"
            />
            <br>
            <h5 class="sub-title">이미지 등록</h5>
            <mdb-file-input
                class="file-input-img size-custom"
                btnTitle="이미지 선택"
                @getValue="getImageFileInputValue"
                icon-right="upload"
                img
                :width="155"
                sm
                accept="image/gif, image/jpeg, image/png"
            />
            <div class="text-right mt-4">
              <mdb-btn outline="success" @click="startUpload()" class="font-25 rounded-btn">의뢰
                <mdb-icon icon="pen" class="font-25"/>
              </mdb-btn>
            </div>
          </form>
        </div>
      </div>
    </mdb-col>
  </mdb-container>
</template>

<script>
import {mdbSelect, mdbContainer, mdbFileInput, mdbBtn, mdbCol, mdbIcon} from 'mdbvue';
import firebase from "firebase/app";

export default {
  name: 'SelectPage',
  components: {
    mdbSelect,
    mdbContainer,
    mdbFileInput,
    mdbBtn,
    mdbCol,
    mdbIcon,
  },
  data() {
    return {
      id: "",
      writer: "",
      email: this.$store.state.user_email,
      title: "",
      contents: "",
      fields: [
        {text: '드론', value: '드론'},
        {text: '웹', value: '웹'},
        {text: '앱', value: '앱'},
        {text: '펌웨어', value: '펌웨어'},
        {text: '하드웨어', value: '하드웨어'}
      ],
      sel_fields: "",
      fileList: [],
      imageFileList: [],
      image_url: '',
      files: {},
      file_url: '',
      file_name: '',
    };
  },
  methods: {
    getSelectValue(value) {
      const self = this;
      self.sel_fields = value;
    },
    getFileInputValue(val) {
      const self = this;
      self.fileList = val;
    },
    getImageFileInputValue(val) {
      const self = this;
      self.imageFileList = val;
    },
    onUploadFile() {
      const self = this;
      const storage = firebase.storage();

      const file = self.fileList[0];
      const storageRef = storage.ref();
      const savePath = storageRef.child('files/' + file.name);
      const upload = savePath.put(file)

      self.files = {};
      self.file_name = file.name;
      upload.on('state_changed', (snapshot) => {
            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log('Upload is running');
                break;
            }
          },
          (error) => {
            console.error('실패사유는', error);
          },
          () => {
            upload.snapshot.ref.getDownloadURL().then((url) => {
              console.log('업로드된 경로는', url);
              self.file_url = url;
              self.files[self.file_name] = self.file_url

              if (self.imageFileList.length !== 0) {
                self.onImageUploadFile();
              } else {
                self.onSend();
              }
            });
          }
      );
    },

    onImageUploadFile() {
      const self = this;
      const storage = firebase.storage();

      const file = self.imageFileList[0];
      const storageRef = storage.ref();
      const savePath = storageRef.child('images/' + file.name);
      const upload = savePath.put(file)

      upload.on('state_changed', (snapshot) => {
            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log('Upload is running');
                break;
            }
          },
          (error) => {
            console.error('실패사유는', error);
          },
          () => {
            upload.snapshot.ref.getDownloadURL().then((url) => {
              console.log('업로드된 경로는', url);
              self.image_url = url;
              self.onSend();
            });
          }
      );
    },
    startUpload() {
      const self = this;
      if (self.title === "" || self.contents === "" || self.email === "" || self.writer === "") {
        alert("모든 항목을 기입해 주세요")
        return;
      }

      if (self.fileList.length !== 0) {
        self.onUploadFile();
      } else {
        if (self.imageFileList.length !== 0) {
          self.onImageUploadFile();
        } else {
          self.onSend();
        }
      }

    },
    onSend() {
      const self = this;
      const db = firebase.firestore();

      const data = {
        writer: self.writer,
        title: self.title,
        email: self.email,
        fields: self.sel_fields,
        contents: self.contents,
        image_url: self.image_url,
        files: self.files,
        views: 0,
        uid: self.$store.state.firebase_uid,
        reg_date: firebase.firestore.Timestamp.fromDate(new Date()),
      }

      db.collection('requestBoard')
          .add(data)
          .then(() => {
            alert('의뢰가 접수되었습니다.');
            this.$router.go(0);
          })
          .catch((err) => {
            console.log(err)
          })
    }
  }
}
</script>

<style scoped>
.sub_content {
  text-align: center;
}

.sub_content h3 {
  margin: 35px auto 0;
  font-family: AppleSDGothicNeo;
  font-size: 35px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 35px;
  letter-spacing: normal;
  text-align: left;
  color: #2b4999;
  position: relative;
}

.search-tool {
  position: relative;
  left: -6px;
}

.fhl {
  position: absolute;
  top: 12px;
  right: 38px;
}

.sub_content p {
  word-break: keep-all;
  font-family: AppleSDGothicNeo, serif;
  padding: 0 1%;
  font-size: 25px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
  max-width: 794px;
  margin: 34px auto 29px;
}

.file-input-doc {
  margin-top: 0;
  margin-bottom: 0;
}

.file-input-img {
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  display: block;
}

.sel_box {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}

.sub-title {
  font-family: AppleSDGothicNeo, serif;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.3;
  letter-spacing: normal;
  text-align: center;
  color: #2b4999;
}

.form-control {
  max-width: 655px;
  margin: 0 auto;
  font-size: 25px !important;
}

.size-custom {
  max-width: 655px;
  margin: 0 auto;
  font-size: 25px !important;
}

.font-25 {
  font-size: 25px !important;
}

.rounded-btn {
  width: 164px;
  height: 48px;
  border-radius: 10px;
  line-height: 0;
  border: solid 1px #00d21d;
  background-color: #fff;
}

.file-input-img {
  display: flex;
}

.file-field.btn-default {
  width: 212px;
  height: 49px;
  margin: 26px 177px 135px 10px;
  padding: 12px 24px 12px 36px;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0px 1px 4.5px 0.6px rgba(0, 0, 0, 0.4);
  background-color: #2b4999;
}
</style>
